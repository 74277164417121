@use 'src/utils/variables' as *;

* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  padding: 3rem;
  background-color: $background-primary;
  color: $text-primary;

  font-family: 'Source Code Pro', monospace, -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
