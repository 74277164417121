@use 'src/utils/mixins' as *;

.App {
  font-size: 1.6rem;
  text-align: center;
  position: relative;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -30%);

  .logo {
    margin-bottom: 3%;

    @include respond(phone) {
      font-size: 1rem;
    }
  }
}
